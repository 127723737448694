import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day4: Content[] = [
    {
        id: "d1182381-8f37-4708-9e2e-bc9be5f123bc",
        text: [
            "Hi <nick name>! Welcome back to wMammogram project!"
        ],
        textStyle: [
            ["text-h5", "text-center"]
        ],
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            left: true
        }
    },
    {
        id: "5f604a84-d0bc-49a0-956c-7544af10ac5b",
        text: [
            "Today, I’d like to talk about conditions that make it hard to get mammograms annually, including insurance, transportation, and other services."
        ],
        question: {
            question: "Many women have said that they don’t get their breasts checked up yearly because of financial reasons or insurance. What is your situation?",
            answers: [
                "I have my insurance that covers mammograms.",
                "Although I have my insurance that partially covers mammograms, I still have to pay for significant amount of money.",
                "I don’t have insurance that partially or completely covers mammograms."
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "Awesome! We’re glad to hear that you’re resourceful! Then do you want us to help you set up an appointment?"
                ],
                [
                    "I’m so sorry to hear that. Actually, we’ve met many women in your situation.",
                    "There is a program called All Women Count that supports free breast cancer screening by the South Dakota State government."
                ],
                [
                    "I’m so sorry to hear that. Actually, we’ve met many women in your situation.",
                    "Fortunately, there is a program called All Women Count that supports free breast cancer screening by the South Dakota State government."
                ]
            ]
        }
    },
    {
        id: "669b9143-faa9-40c8-b799-7728f770654f",
        text: [
            "Have you ever heard of the All Women Count program? This program supports free breast cancer screening by the South Dakota State government.",
            "You are able to get All Women Count services when you meet all the following criteria:",
            "1) Age 40 or older",
            "2) Have no insurance or are under-insured",
            "3) Income within All Women Count guidelines.",
            "<Guidelines link:https://doh.sd.gov/prevention/all-women-count.aspx>"
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Allwomencount.mp4"),
            },
            drive: {
                sd: "1Ja7F788hYRVlezOy9Is90LD7T9aFw6Ne",
                hd: "1I6ztv0BAe8qLrkSog_SsS56R71bjuj7q",
                uhd: "1KuilTCTYxFhv6l0DHKR4Elr5YX0BD3sX"
            }
        },
    },
    {
        id: "3f321d2b-b295-4f57-9698-727830ccb69d",
        text: [
            "If we have a group of five people who want to receive a mammogram at the same time, the process for a mammogram would be much easier and simple. Also, you can get a free ride!",
            "Do you want to see whether you’re eligible for this program? Please call us tel:605-202-1418 605-202-1418!"
        ]
    },
    {
        id: "b838d039-be85-4a9e-9385-d328ff072d60",
        text: [
            "Some women have said that it has been difficult to get mammograms because they don’t have reliable transportation to a clinic."
        ],
        question: {
            question: "Do you think you have the same issue?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 1,
            time: 0,
            text: [
                [
                    "Here’s good news for you! Our team can give you a ride to a clinic"
                ],
                [
                    "It is great that you don’t have a transportation problem!",
                    "Then do you want us to help you set up an appointment?",
                    "Please call us or you may text us back tel:605-202-1418 605-202-1418. We are delighted to help you!"
                ]
            ]
        }
    },
    {
        id: "3253bb16-aef0-4b0e-8c80-745e1806d496",
        question: {
            question: "Insurance, transportation, and language is not a problem for you anymore, but you don’t have time to get a mammogram for yourself?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 1,
            time: 0,
            text: [
                [
                    "We understand how busy and hard it is to live as a mother and partner.",
                    "However, mammogram itself takes 10-15 minutes and you will need maximum 1-2 hours total including your transportation time.",
                ],
                [
                    "Great!",
                    "Please make sure that you’re managing your health when you’re in a good shape!!"
                ]
            ],
            common: [
                "The most important thing in our life is health.",
                "I’m sure you have heard the saying \"you will lose everything if you become sick.\"",
                "Our pink ribbons are caring about your health to make your life happier."
            ]
        }
    },
    {
        id: "cb1a7594-1b1a-41d8-b684-1ab36c763365",
        text: [
            "Sometimes, you may need to make an appointment separately for a primary care visit and a mammogram.",
            "Is this your case and is it kind of cumbersome for you?",
            "We can help you to go through this complex health care system so that you can get a mammogram!"
        ],
        question: {
            question: "Has your primary health care provider ever encouraged you to get a clinical breast exam or mammogram?",
            answers: [
                "No",
                "Yes"
            ],
            correct: 1,
            time: 0,
            text: [
                [
                    "Hmm... It will be better for you to ask a question about your risk for breast cancer.",
                    "Initiate the conversation with your health care provider about breast cancer screening."
                ],
                [
                    "That’s great!",
                    "It is always good to follow up regularly with your primary health care provider about your potential risk for breast cancer!"
                ]
            ],
            pinkie: {
                incorrect: require("@/assets/pinkie/worried.png")
            }
        }
    },
    {
        id: "73869696-dc80-44d7-ba1a-67f26622af23",
        text: [
            "There are several barriers that make it hard to get regular breast cancer screenings! But we will not be stopped by them!",
            "Make sure that you’re fully utilizing resources out there! If you have any questions, or want to share your concerns with us, remember that we’re always open to you!!!",
            "Thanks for your participation!",
            "You will receive a notification later to inform you how many pink ribbons you have received today."
        ]
    }
];

export const manager = new ContentManager(day4);

export default day4;